const compareJSON = (obj1, obj2) => {
  const differences = {};

  const findDifferences = (o1, o2, path = "") => {
    if (
      typeof o1 !== "object" ||
      typeof o2 !== "object" ||
      o1 === null ||
      o2 === null
    ) {
      if (o1 !== o2) {
        differences[path || "root"] = { value1: o1, value2: o2 };
      }
      return;
    }

    const keys = new Set([...Object.keys(o1 || {}), ...Object.keys(o2 || {})]);

    keys.forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      findDifferences(o1?.[key], o2?.[key], newPath);
    });
  };

  findDifferences(obj1, obj2);

  // Return true if there are differences, otherwise false
  return Object.keys(differences).length > 0;
};
export default compareJSON;
