import ApiModel from "./../api/api_model";
import Connector from "./../api/http_connector";
import { checkTemplate } from "../helpers/template_utils";
import compareJSON from "../helpers/payload_checker";
import notifications from "../helpers/notifications";

const connector = new Connector({
  link: process.env.REACT_APP_API_LINK,
  endpoint: process.env.REACT_APP_ENDPOINT,
});

class apiModelAuth extends ApiModel {
  auth(callback) {
    const { otp_param, fid } = window.uri_params;
    if (!otp_param && !fid) {
      return callback({
        api_error: { message: "Data error: Keys don't exist" },
      });
    }
    const payload = {
      mount_name: "auth",
      mount_type: "validate_OTP_keys",
      params: {
        otp_param,
      },
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        const { otp_param_s64, token, auth } = res.api_data;
        window.uri_params.otp_param = otp_param_s64;
        window.token = token;
        window.auth = auth;
        callback(false, true);
      } else {
        console.log(err);
        if (err?.api_error?.message) callback(err);
        else callback({ message: "Auth: Wrong keys" });
      }
    });
  }
  load_survey(callback) {
    const payload = {
      auth: window.auth,
      mount_name: "websurvey",
      mount_type: "load_survey",
      params: {
        fid: window.uri_params.fid,
        au_aid: window.uri_params.au_aid,
      },
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
  load_template(fid, callback) {
    const payload = {
      auth: window.auth,
      mount_name: "webtemplate",
      mount_type: "load_template",
      params: {
        fid: fid,
      },
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        //window.uri_params.otp_param = res.api_data.otp_param_s64;
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
  save_survey(template, answers, callback) {
    const payload = {
      auth: window.auth,
      mount_name: "websurvey",
      mount_type: "save_survey",
      params: {
        fid: window.uri_params.fid,
        au_aid: window.uri_params.au_aid,
      },
    };

    if (window.dest_entid) {
      payload.params.dest_entid = window.dest_entid;
    }
    // Check template status and calculate answered quantity
    const templateStatus = checkTemplate(template);
    const answeredQty = Object.keys(answers).length;

    // Prepare the survey payload
    const receivedPayload = { ...window.whole_payload };
    if (window.dest_entid) {
      receivedPayload.dest_entid = window.dest_entid;
    }

    // Add payload answers to the survey payload received from the server
    receivedPayload.arr_antworten = {
      ...receivedPayload.arr_antworten,
      ...answers,
    };
    //for testing payload and response mismatch purposes
    // console.log("received before Payload answers", receivedPayload.arr_antworten);
    // delete receivedPayload.arr_antworten.testkey; 
    // console.log("received after Payload answers", receivedPayload.arr_antworten);
    // receivedPayload.arr_antworten.testkey = Date.now();
    // console.log("send Payload answers", answers);

    const hasDifferences = compareJSON(
      answers || {},
      receivedPayload.arr_antworten
    );

    if (hasDifferences) {
      console.log("Payload and Response do not match");
      setTimeout(
        () =>
          notifications.error_top_full(
            null,
            "Payload and Response is not matching"
          ),
        100
      );
    } else {
      console.log("Payload and Response matched");
    }

    receivedPayload.no_of_quest = templateStatus.questions_info.qty;
    receivedPayload.no_of_quest_answered = answeredQty;
    receivedPayload.test_manipulate_arr_antworten = "yes";

    // Encode the survey payload as a JSON string
    const payloadData = JSON.stringify(receivedPayload);
    payload.params.data = { qarr_json64: payloadData };

    this._connectApi(payload, (err, res) => {
      if (!err) {
        //window.uri_params.otp_param = res.api_data.otp_param_s64;
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
}

const apiModel = new apiModelAuth(connector);

export default apiModel;
