import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const SelectField = ({
  name,
  value = "",
  options = [],
  option_objects,
  className = "",
  postfix = "",
  label,
  placeholder,
  allowEmpty = false,
  isDisabled = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChange = (val) => {
    onChange({ name, value: val?.value, label: val?.label });
  };

  const builtOptions = [
    ...(Array.isArray(options) ? options : []),
    ...(option_objects?.objects_array?.map(optionObject => ({
      label: optionObject[option_objects.field_name_for_label],
      value: optionObject[option_objects.field_name_for_value],
    })) || [])
  ];
  
//   const namePostfix = postfix ? `${name}_${postfix}` : `${name}_edit`;
  const selectValue = value?.currentValue
    ? builtOptions.find((option) => option.value === value?.currentValue)
    : builtOptions[0];


  return (
    <div className={`select field_wrap ${name} ${className || ''}`}>
      <Select
        name={postfix ? `${name}_${postfix}` : `${name}_edit`}
        isClearable={allowEmpty}
        className={className}
        classNamePrefix="select-field"
        label={t(label)}
        inputProps={{ id: name }}
        value={selectValue}
        isSearchable={false}
        width="200px"
        placeholder={t(placeholder)}
        options={builtOptions}
        onChange={handleChange}
        menuPortalTarget={document.querySelector("body")}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectField;
